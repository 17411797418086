<template>
  <div>
    <!-- 显示作业令上刊汇总报表   -->
    <div v-if="showReport">
      <div class="p-t-20" style="width:100%;height:280px;" id="jobOrderReport1"></div>
    </div>

    <div v-else class="default-content">
        <p style="font-size: x-large;">未发现上刊数据</p>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  data () {
    return {
      showReport: true
    }
  },
  methods: {
    initReport (stations) {
      if (stations.length === 0) {
        this.showReport = false
      } else {
        this.showReport = true

        // 格式化站点信息
        const xStationLables = stations.map(x => x.name)
        // 获取轨行区的上刊数量
        const yGXQ = []
        const yOther = []

        stations.forEach(element => {
          const GXQlist = element.positionQuantityList.filter(x => x.position === 1)
          const Otherlist = element.positionQuantityList.filter(x => x.position !== 1)

          let GXQCount = 0
          GXQlist.forEach(element => {
            GXQCount += element.quantity
          })
          yGXQ.push(GXQCount)

          let OtherCount = 0
          Otherlist.forEach(element => {
            OtherCount += element.quantity
          })
          yOther.push(OtherCount)
        })

        this.loadChart(xStationLables, yGXQ, yOther)
      }
    },
    loadChart (xStationLables, yGXQ, yOther) {
      this.$nextTick(() => {
        var dataSourcePie = echarts.init(document.getElementById('jobOrderReport1'))

        const option = {
          color: ['#FA8B59', '#4285F4'],
          textStyle: {
            color: '#fff'
          },
          title: {
            text: '当前作业令作业量汇总',
            textStyle: {
              color: '#E5E5E5'
            }
          },
          grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '0%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['轨行区', '非轨行区'],
            textStyle: {
              color: '#E5E5E5'
            },
            right: 0
          },
          calculable: true,
          xAxis: [
            {
              type: 'category',
              data: xStationLables,
              axisLabel: {
                color: '#c5c8ce'
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '轨行区',
              type: 'bar',
              data: yGXQ,
              markPoint: {
                data: [
                  { type: 'max', name: '最大值' },
                  { type: 'min', name: '最小值' }
                ]
              },
              markLine: {
                data: [
                  { type: 'average', name: '平均值' }
                ]
              }
            },
            {
              name: '非轨行区',
              type: 'bar',
              data: yOther,
              markPoint: {
                data: [
                  { type: 'max', name: '最大值' },
                  { type: 'min', name: '最小值' }
                ]
              },
              markLine: {
                data: [
                  { type: 'average', name: '平均值' }
                ]
              }
            }
          ]
        }

        dataSourcePie.setOption(option, true)
        window.addEventListener('resize', function () {
          dataSourcePie.resize()
        })
      })
    }
  }
}
</script>
